.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.4);
    z-index: 2000;
    cursor: pointer;
  }

  .loader {
    position: absolute;
    top:50%;
    left:50%;
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 16px;
    height: 16px;
    z-index:5;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  .center-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay-text {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 40px;
    color: black;
  }

  .overlay-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay-headline {
    margin-bottom: 40px;
    font-size: 1.1rem;
    color: white;
    font-weight: bold;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }