body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @font-face {
  font-family: "gilroy";
  src: url("public/font/fonts");
  src: url("css/fonts/Harabara.woff") format("woff"),
    url("css/fonts/Harabara.otf") format("opentype"),
    url("css/fonts/Harabara.svg#filename") format("svg");
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
