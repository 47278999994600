body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.strike-through {
  text-decoration: line-through;
}

.under-line {
  text-decoration: underline;
}

.grid_1_3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

/*
******************************************************************************
*****************************************************************************

GRID

********************************************************************************
********************************************************************************

*/

.colGrid-2 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: repeat(2, 50%);
  grid-template-rows: repeat(2, 50%);
}

.colGrid-4 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: repeat(4, 25%);
  grid-template-rows: repeat(4, 25%);
}

.row-2 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: repeat(2, 50%);
  grid-template-columns: repeat(2, 50%);
}

.row-3 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: repeat(3, 33.33%);
  grid-template-columns: repeat(3, 33.33%);
}

.row-67 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: repeat(3, 36.33%);
  grid-template-columns: repeat(3, 36.33%);
}

.row-4 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: repeat(4, 25%);
  grid-template-columns: repeat(4, 25%);
}

.row-5 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: repeat(5, 20%);
  grid-template-columns: repeat(5, 20%);
}

.row-6 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: repeat(6, 16.66%);
  grid-template-columns: repeat(6, 16.66%);
}

.row-7 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: repeat(7, 14.28%);
  grid-template-columns: repeat(7, 14.28%);
}

.row-10 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: repeat(10, 10%);
  grid-template-columns: repeat(10, 10%);
}

.row-8 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: repeat(8, 12.5%);
  grid-template-columns: repeat(8, 12.5%);
}

.row-12 {
  display: -ms-grid;
  display: grid;
}

.row-18 {
  display: -ms-grid;
  display: grid;
}

.row-24 {
  display: -ms-grid;
  display: grid;
}

.col-2 {
  -ms-grid-column: span 2;
  grid-column: span 2;
}

.col-3 {
  -ms-grid-column: span 3;
  grid-column: span 3;
}

.col-4 {
  -ms-grid-column: span 4;
  grid-column: span 4;
}

.col-5 {
  -ms-grid-column: span 5;
  grid-column: span 5;
}

.col-6 {
  -ms-grid-column: span 6;
  grid-column: span 6;
}

.col-7 {
  -ms-grid-column: span 7;
  grid-column: span 7;
}

.col-8 {
  -ms-grid-column: span 8;
  grid-column: span 8;
}

.col-9 {
  -ms-grid-column: span 9;
  grid-column: span 9;
}

.col-10 {
  -ms-grid-column: span 10;
  grid-column: span 10;
}

.col-11 {
  -ms-grid-column: span 11;
  grid-column: span 11;
}

.col-12 {
  -ms-grid-column: span 12;
  grid-column: span 12;
}

.col-13 {
  -ms-grid-column: span 13;
  grid-column: span 13;
}

.col-14 {
  -ms-grid-column: span 14;
  grid-column: span 14;
}

.col-15 {
  -ms-grid-column: span 15;
  grid-column: span 15;
}

.col-16 {
  -ms-grid-column: span 16;
  grid-column: span 16;
}

.col-17 {
  -ms-grid-column: span 17;
  grid-column: span 17;
}

.col-18 {
  -ms-grid-column: span 18;
  grid-column: span 18;
}

.col-19 {
  -ms-grid-column: span 19;
  grid-column: span 19;
}

.col-20 {
  -ms-grid-column: span 20;
  grid-column: span 20;
}

.col-21 {
  -ms-grid-column: span 21;
  grid-column: span 21;
}

.col-22 {
  -ms-grid-column: span 22;
  grid-column: span 22;
}

.col-23 {
  -ms-grid-column: span 23;
  grid-column: span 23;
}

.col-24 {
  -ms-grid-column: span 24;
  grid-column: span 24;
}

.itemBox {
  width: auto;
  height: auto;
  background-color: rgb(207, 79, 79);
  font-size: 20px;
  font-weight: 600;
  padding: 10px 0px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .row-12 {
    -ms-grid-columns: repeat(4, auto);
    grid-template-columns: repeat(4, auto);
  }
  .row-18 {
    -ms-grid-columns: repeat(6, auto);
    grid-template-columns: repeat(6, auto);
  }
  .row-24 {
    -ms-grid-columns: repeat(8, auto);
    grid-template-columns: repeat(8, auto);
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .row-12 {
    -ms-grid-columns: repeat(4, auto);
    grid-template-columns: repeat(4, auto);
  }
  .row-18 {
    -ms-grid-columns: repeat(6, auto);
    grid-template-columns: repeat(6, auto);
  }
  .row-24 {
    -ms-grid-columns: repeat(8, auto);
    grid-template-columns: repeat(8, auto);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .row-12 {
    -ms-grid-columns: repeat(8, auto);
    grid-template-columns: repeat(8, auto);
  }
  .row-18 {
    -ms-grid-columns: repeat(12, auto);
    grid-template-columns: repeat(12, auto);
  }
  .row-24 {
    -ms-grid-columns: repeat(16, auto);
    grid-template-columns: repeat(16, auto);
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .row-12 {
    -ms-grid-columns: repeat(8, auto);
    grid-template-columns: repeat(8, auto);
  }
  .row-18 {
    -ms-grid-columns: repeat(12, auto);
    grid-template-columns: repeat(12, auto);
  }
  .row-24 {
    -ms-grid-columns: repeat(16, auto);
    grid-template-columns: repeat(16, auto);
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .row-12 {
    -ms-grid-columns: repeat(12, auto);
    grid-template-columns: repeat(12, auto);
  }
  .row-18 {
    -ms-grid-columns: repeat(18, auto);
    grid-template-columns: repeat(18, auto);
  }
  .row-24 {
    -ms-grid-columns: repeat(24, auto);
    grid-template-columns: repeat(24, auto);
  }
}

@media (min-width: 1281px) {
  .row-12 {
    -ms-grid-columns: repeat(12, auto);
    grid-template-columns: repeat(12, auto);
  }
  .row-18 {
    -ms-grid-columns: repeat(18, auto);
    grid-template-columns: repeat(18, auto);
  }
  .row-24 {
    -ms-grid-columns: repeat(24, auto);
    grid-template-columns: repeat(24, auto);
  }
}

/*
******************************************************************************
*****************************************************************************

MARGIN

********************************************************************************
********************************************************************************

*/

.margin_0 {
  margin: 0px !important;
}

.margin_6 {
  margin: 6px !important;
}

.margin_lr_2 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.margin_t_4 {
  margin-top: 4px;
}

.margin_b_4 {
  margin-bottom: 4px;
}

.margin_tb_4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.margin_l_4 {
  margin-left: 4px;
}

.margin_r_4 {
  margin-right: 4px;
}

.margin_lr_4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.margin_lr_6 {
  margin-left: 6px !important;
  margin-right: 6px !important;
}
.margin_lr_8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.margin_lr_10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.margin_10 {
  margin: 10px !important;
}

.margin_tr_1rem {
  margin-top: 1rem;
  margin-right: 1rem;
}
.margin_tb_8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.margin_tb_3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.margin_tb_6 {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.margin_tb_10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.margin_t_1rem {
  margin-top: 1rem;
}

.margin_r_3rem {
  margin-right: 3rem;
}

.margin_l_8 {
  margin-left: 8px;
}

.margin_r_8 {
  margin-right: 8px;
}

.margin_t_6 {
  margin-top: 6px;
}

.margin_r_1rem {
  margin-right: 1rem;
}

.margin_r_16 {
  margin-right: 16px;
}

.margin_t_12 {
  margin-top: 12px;
}

.margin_b_12 {
  margin-bottom: 12px;
}

.margin_tb_12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.margin_l_12 {
  margin-left: 12px;
}

.margin_r_12 {
  margin-right: 12px;
}

.margin_lr_12 {
  margin-left: 12px;
  margin-right: 12px;
}

.margin_t_14 {
  margin-top: 14px;
}

.margin_b_14 {
  margin-bottom: 14px;
}

.margin_t_20 {
  margin-top: 20px;
}

.margin_b_8 {
  margin-bottom: 8px;
}

.margin_b_20 {
  margin-bottom: 20px;
}

.margin_t_16 {
  margin-top: 16px;
}

.margin_b_16 {
  margin-bottom: 16px;
}

.margin_tb_16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.margin_l_16 {
  margin-left: 16px;
}

.margin_r_16 {
  margin-right: 16px;
}

.margin_lr_16 {
  margin-left: 16px;
  margin-right: 16px;
}

.margin_t_18 {
  margin-top: 18px;
}

.margin_b_18 {
  margin-bottom: 18px;
}

.margin_tb_18 {
  margin-top: 18px;
  margin-bottom: 18px;
}

.margin_l_18 {
  margin-left: 18px;
}

.margin_r_18 {
  margin-right: 18px;
}

.margin_lr_18 {
  margin-left: 18px;
  margin-right: 18px;
}

.margin_tb_20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin_r_22 {
  margin-right: 22px;
}

.margin_b_40 {
  margin-bottom: 40px;
}

.margin_t_70 {
  margin-top: 70px;
}

.margin_b_70 {
  margin-bottom: 70px;
}

.marginAuto {
  margin: auto;
}

/****************************************************************************************
-------- PADDING ----------
****************************************************************************************/

.noPaddingNoMargin {
  padding: 0px !important;
  margin: 0px !important;
}

.padding_1vw {
  padding: 1vw;
}

.padding_lr_1vw {
  padding-left: 1vw;
  padding-right: 1vw;
}

.padding_0 {
  padding: 0px !important;
}

.padding_2 {
  padding: 2px;
}

.padding_4 {
  padding: 4px;
}

.padding_6 {
  padding: 6px;
}

.padding_8 {
  padding: 8px;
}

.padding_10 {
  padding: 10px;
}

.padding_12 {
  padding: 12px;
}

.padding_14 {
  padding: 14px;
}

.padding_16 {
  padding: 16px;
}

/* 0 */
.padding_tb_0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.padding_lr_0 {
  padding-left: 0px;
  padding-right: 0px;
}

.padding_l_0 {
  padding-left: 0;
}

.padding_r_0 {
  padding-right: 0px;
}

.padding_b_0 {
  padding-bottom: 0px;
}

.padding_t_0 {
  padding-top: 0px;
}

/* 2 */
.padding_tb_2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.padding_lr_2 {
  padding-left: 2px;
  padding-right: 2px;
}

.padding_l_2 {
  padding-left: 2px;
}

.padding_r_2 {
  padding-right: 2px;
}

.padding_b_2 {
  padding-bottom: 2px;
}

.padding_t_2 {
  padding-top: 2px;
}

/* 4 */
.padding_tb_4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.padding_lr_4 {
  padding-left: 4px;
  padding-right: 4px;
}

.padding_l_4 {
  padding-left: 4px;
}

.padding_r_4 {
  padding-right: 4px;
}

.padding_b_4 {
  padding-bottom: 4px;
}

.padding_t_4 {
  padding-top: 4px;
}

/* 6 */
.padding_tb_6 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.padding_lr_6 {
  padding-left: 6px;
  padding-right: 6px;
}

.padding_l_6 {
  padding-left: 6px;
}

.padding_r_6 {
  padding-right: 6px;
}

.padding_b_6 {
  padding-bottom: 6px;
}

.padding_t_6 {
  padding-top: 6px;
}

/* 8 */
.padding_tb_8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.padding_lr_8 {
  padding-left: 8px;
  padding-right: 8px;
}

.padding_b_8 {
  padding-bottom: 8px;
}

.padding_t_8 {
  padding-top: 8px !important;
}

.padding_l_8 {
  padding-left: 8px;
}

.padding_r_8 {
  padding-right: 8px;
}

/* 1rem */
.padding_tr_1rem {
  padding-top: 1rem;
  padding-right: 1rem;
}

.padding_tb_1rem {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.padding_t_1rem {
  padding-top: 1rem;
}

.padding_b_1rem {
  padding-bottom: 1rem;
}

/* 10 */
.padding_tb_10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding_lr_10 {
  padding-left: 10px;
  padding-right: 10px;
}

.padding_b_10 {
  padding-bottom: 10px;
}

.padding_l_10 {
  padding-left: 10px;
}

.padding_r_10 {
  padding-right: 10px;
}

.padding_t_10 {
  padding-top: 10px;
}

.padding_b_11 {
  padding-bottom: 11.5px;
}

/* 12 */
.padding_tb_12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.padding_lr_12 {
  padding-left: 12px;
  padding-right: 12px;
}

.padding_t_12 {
  padding-top: 12px;
}

.padding_b_12 {
  padding-bottom: 12px;
}

.padding_l_12 {
  padding-left: 12px;
}

.padding_r_12 {
  padding-right: 12px;
}

.padding_t_13 {
  padding-top: 13px;
}

.padding_tb_14 {
  padding-top: 14px;
  padding-bottom: 14px;
}

/* 14 */
.padding_lr_14 {
  padding-left: 14px;
  padding-right: 14px;
}

.padding_t_14 {
  padding-top: 14px;
}

.padding_b_14 {
  padding-bottom: 14px;
}

.padding_l_14 {
  padding-left: 14px;
}

.padding_r_14 {
  padding-right: 14px;
}

/* 16 */
.padding_tb_16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.padding_lr_16 {
  padding-left: 16px;
  padding-right: 16px;
}

.padding_l_16 {
  padding-left: 16px;
}

.padding_r_16 {
  padding-right: 16px;
}

.padding_t_16 {
  padding-top: 16px;
}

.padding_b_16 {
  padding-bottom: 16px;
}

/* 18 */
.padding_tb_18 {
  padding-top: 18px;
  padding-bottom: 18px;
}

.padding_lr_18 {
  padding-left: 18px;
  padding-right: 18px;
}

.padding_l_18 {
  padding-left: 18px;
}

.padding_r_18 {
  padding-right: 18px;
}

.padding_t_18 {
  padding-top: 18px;
}

.padding_b_18 {
  padding-bottom: 18px;
}

/* 2rem */
.padding_tb_2rem {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/* 20 */
.padding_tb_20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding_lr_20 {
  padding-left: 20px;
  padding-right: 20px;
}

.padding_t_20 {
  padding-top: 20px;
}

.padding_b_20 {
  padding-bottom: 20px;
}

.padding_lr_22 {
  padding-left: 22px;
  padding-right: 22px;
}

/* 24 */
.padding_t_24 {
  padding-top: 24px;
}

.padding_tb_24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

/* 28 */
.padding_t_28 {
  padding-top: 28px;
}

.padding_tb_28 {
  padding-top: 28px;
  padding-bottom: 28px;
}

/* 30 */
.padding_l_30 {
  padding-left: 30px;
}

.padding_t_30 {
  padding-top: 30px;
}

.padding_b_30 {
  padding-bottom: 30px;
}

.padding_r_30 {
  padding-right: 30px;
}

/* 34 */
.padding_lr_34 {
  padding-left: 34px;
  padding-right: 34px;
}

.padding_tb_34 {
  padding-top: 34px;
  padding-bottom: 34px;
}

/* 36 */
.padding_tb_36 {
  padding-top: 36px;
  padding-bottom: 36px;
}

/* 40 */
.padding_tb_40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.padding_t_40 {
  padding-top: 40px;
}

.padding_b_40 {
  padding-bottom: 40px;
}

/* 44 */
.padding_tb_44 {
  padding-top: 44px;
  padding-bottom: 44px;
}

/* 50 */
.padding_tb_50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

/* 60 */
.padding_tb_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.padding_t_60 {
  padding-top: 60px;
}

.padding_b_60 {
  padding-bottom: 60px;
}

.padding_b_90 {
  padding-bottom: 90px;
}

/****************************************************************************************
-------- POSITION ----------
****************************************************************************************/

.absolute-position {
  position: absolute;
}

.relative-position {
  position: relative;
}

/****************************************************************************************
-------- TEXT-ALIGN ----------
****************************************************************************************/

.textAlignCenter {
  text-align: center !important;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.textAlignEnd {
  text-align: end;
}

.textAlignStart {
  text-align: start;
}

/****************************************************************************************
-------- CURSOR ----------
****************************************************************************************/

.cursor-pointer {
  cursor: pointer;
}

.cursor_default {
  cursor: default;
}

.cursor_not_allowed {
  cursor: not-allowed;
}

/****************************************************************************************
---------- DISPLAY & FLEX PROPERTIES -----------
*****************************************************************************************/

.makeBlock {
  display: block;
}

.makeInlineBlock {
  display: inline-block;
}

.flexRowStart {
  display: flex;
}

.display_flex {
  display: flex;
}

.flex_1 {
  flex: 1;
}

.flexRowEnd {
  display: flex;
  flex-direction: row-reverse;
}

.flexColumnStart {
  display: flex;
  flex-direction: column;
}

.flexRowStart {
  display: flex;
  flex-direction: row;
}

.flexWrap {
  flex-wrap: wrap;
}

.placeAtCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.placeAtCenterAndBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.placeAtVerticalCenter {
  display: flex;
  align-items: center;
}

.placeAtHorizontalCenter {
  display: flex;
  align-items: center;
}

.placeAtEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.align_items_center {
  align-items: center;
}

.align_items_end {
  align-items: end;
}

.justify_center {
  justify-content: center;
}

.justify_end {
  justify-content: end;
}

.justify_flex_end {
  justify-content: flex-end;
}

.justify_space_between {
  justify-content: space-between;
}

/****************************************************************************************
-------- FONT-WEIGHT ----------
****************************************************************************************/

.fontWeightNormal {
  font-weight: normal;
}

.fontWeightBold {
  font-weight: bold;
}

.fontWeight500 {
  font-weight: 500;
}

.fontWeight600 {
  font-weight: 600;
}

.fontWeight700 {
  font-weight: 700;
}

/****************************************************************************************
-------- FONT-SIZE ----------
****************************************************************************************/

.font_8 {
  font-size: 8px !important;
}

.font_10 {
  font-size: 10px !important;
}

.font_12 {
  font-size: 12px !important;
}

.font_14 {
  font-size: 14px !important;
}

.font_16 {
  font-size: 16px !important;
}

.font_18 {
  font-size: 18px !important;
}

.font_20 {
  font-size: 20px !important;
}

.font_22 {
  font-size: 22px !important;
}

.font_24 {
  font-size: 24px !important;
}

.font_26 {
  font-size: 26px !important;
}

.font_28 {
  font-size: 28px !important;
}

/****************************************************************************************
-------- FONT-FAMILY ----------
****************************************************************************************/

.font_family_roboto {
  font-family: Roboto;
}

/**************************************************************************************
----------- COLOR ----------
************************************************************************************/

.whiteFont {
  color: #fff !important;
}

.purpleFont {
  color: #6a82f5 !important;
}

.lightredFont {
  color: #ff4355 !important;
}

.lightGreenFont {
  color: #2ecc71 !important;
}

.greenFont {
  color: hsl(140, 100%, 30%) !important;
}

.lightGrayFont {
  color: #f3f3f3 !important;
}

.disabledGrayFont {
  color: #d0d0d0 !important;
}

.grayFont {
  color: #9b9b9b !important;
}

.blackFont {
  color: black;
}

.lightBlackFont {
  color: #4a4a4a !important;
}

.yellowFont {
  color: #f7c441;
}

.color_1a {
  color: #1a1a1a;
}

.color_e8 {
  color: #e8e8e8;
}

.color_f5 {
  color: #f5f5f5;
}

/****************************************************************************************
------------- BACKGROUND-COLOR -----------------
****************************************************************************************/

.darkGrayBackground {
  background-color: #9b9b9b !important;
}

.disableDarkGrayBackground {
  background-color: #d0d0d0 !important;
}

.greenBackground {
  background-color: #2ecc71 !important;
}

.lightGrayBackground {
  background-color: #f3f3f3 !important;
}

.lightestGrayBackground {
  background-color: hsl(60, 33%, 80%) !important;
}

.grayBackground {
  background-color: #eee !important;
}

.whiteBackground {
  background-color: #fff !important;
}

.blackBackground {
  background-color: #000;
}

.redBackground {
  background-color: red;
}

.transParentBackground {
  background-color: transparent !important;
}

/****************************************************************************************
-------- WIDTH, HEIGHT -------------
****************************************************************************************/

.fitContentHeight {
  height: fit-content;
}

.fitContentWidth {
  width: fit-content;
}

.minContentWidth {
  width: min-content;
}

.fullHeight {
  height: 100% !important;
}

.fullWidth {
  width: 100% !important;
}

.width_75 {
  width: 75%;
}

.width_50 {
  width: 50%;
}

.width_30 {
  width: 30%;
}

.width_25 {
  width: 25%;
}

.width_31_5 {
  width: 31.5%;
}

.widthFitContent {
  width: fit-content;
}

/****************************************************************************************
-------- BORDER ----------
****************************************************************************************/

.blackBorder {
  border: 1px solid black;
}

.redBorder {
  border: 1px solid #ff4355 !important;
}

.blueBorder {
  border: 1px solid #6a82f5 !important;
}

.grayBorder {
  border: solid 1px #d0d0d0;
}

.divider {
  border: solid 1px #dfe1f3;
}

/****************************************************************************************
-------- BORDER-RADIUS ----------
****************************************************************************************/

.borderRadius_12 {
  border-radius: 12px;
}

.borderRadius_10 {
  border-radius: 10px;
}

.borderRadius_8 {
  border-radius: 8px;
}

.borderRadius_6 {
  border-radius: 6px;
}

.borderRadius_4 {
  border-radius: 4px;
}

/*************************************************************************************
  *************************************************************************************/

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, 6px) scale(0.75) !important;
}

.MuiInputLabel-outlined {
  transform: translate(14px, 6px) scale(0.75) !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 8px !important;
  padding-right: 40px !important;
}

.MuiInputBase-root {
  height: 48px;
  background-color: rgba(255, 255, 255, 0.36);
  opacity: 0.9;
}

.MuiOutlinedInput-input {
  padding: 22px 14px 12px 14px !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input:first-child {
  padding-left: 6px !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  padding: 10.5px 4px !important;
}

/************************************************************************************
  SCROLL BAR
  ************************************************************************************/
/* width */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eeeeee;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.buttonsAttachedwithInputBox {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.flexGaps {
  display: flex;
  justify-content: space-between;
}

/**********************************************************************************************
  ************************ Buttons and Input Tags *****************************************************/

.btn {
  width: 149px;
  height: 48px;
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  font-weight: 600;
  font-size: small;
  margin: 0px 10px;
}

.whiteButton {
  min-width: 149px;
  height: 48px;
  border-radius: 10px;
  border: 2px solid gray;
  background-color: #ffffff;
  text-align: center;
  color: gray;
  text-decoration: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  font-weight: 600;
  font-size: small;
}

.purpleButton {
  min-width: 149px;
  height: 48px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6a82f5;
  border: 2px solid #6a82f5;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  font-weight: 600;
  font-size: small;
}

.whiteButton:hover,
.purpleButton:hover {
  opacity: 0.8;
}

.purpleButton:disabled {
  background-color: rgb(170, 170, 170);
  cursor: inherit;
  border: 2px solid rgb(170, 170, 170);
  color: #000000;
}

.greenBtn {
  background-color: green;
  border: 2px solid green;
}

.grayButtonDisabled {
  /* width: 104px; */
  height: 48px;
  object-fit: contain;
  opacity: 0.9;
  border-radius: 10px;
  background-color: #d0d0d0;
}

.grayButton {
  /* width: 104px; */
  height: 48px;
  object-fit: contain;
  opacity: 0.9;
  border-radius: 10px;
  background-color: #9b9b9b;
}

table th:nth-child(1) {
  border-radius: 10px 0px 0px 0px;
}

table th:nth-last-child(1) {
  border-radius: 0px 10px 0px 0px;
}

.overlayDiv {
  width: 100vw;
  height: 140vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  /* background: gray; */
}

.positionTop_165vh {
  top: 960px;
  /* top: 165vh; */
}

/****************************************************
**********   remove input type number arrows  ***/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 25px;
  height: 100%;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #9b9b9b;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #7b7c7e;
  border-radius: 10px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-row-70-30 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 7fr 3fr;
  grid-template-columns: 7fr 3fr;
}

.custom-row-50-50 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 5fr 5fr;
  grid-template-columns: 5fr 5fr;
}

.MuiDialog-paperWidthLg {
  height: 95%;
}

.agent-edit-header {
  color: #a0a0a0;
  font-size: 12px;
  padding: 0 10px;
}

.agent-editor {
  display: flex;
}

.agent-editor > div {
  width: 100%;
}

.agent-editor .clear-selection-btn {
  border: none;
  margin-left: 1rem;
  background-color: crimson;
}

.pointer-none {
  pointer-events: none;
}

.underLineRedBlackFont {
  color: black;
  text-decoration: underline;
  text-decoration-color: red;
}

.carouselContainer {
  
}

.carouselHeadline1 {
  text-align: center;
  font-weight: bold;
  font-size: 1.3rem;
  padding: 10px 0px;
  color: #312f2f;
}

.toUpperCase {
  text-transform: uppercase;
}